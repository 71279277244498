body {
  padding: 0;
  margin: 0;
  font-family: Arial;
}

.header {
  display: flex;
  padding: 12px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  justify-content: space-between;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  display: flex;
  align-items: center;
}

.logo-img {
  height: 60px;
  width: 60px;
  margin-right: 16px;
}

.page-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 35px;
}

.header-item {
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 16px;
  margin-top: 100px;
  gap: 20px;
}

.motto {
  margin: 0;
  padding: 0;
  font-size: 40px;
  line-height: 60px;
}

.content {
  line-height: 22px;
}

.content-img {
  height: 100%;
  width: 100%;
}

.grayish {
  background-color: lightgray;
}

.showcase {
  display: flex;
  background-color: #f2f2f2;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
}

.showcase-img {
  height: 100px;
  width: 100px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 25px;
}

.showcase-title {
  margin: 0;
  font-size: 25px;
}

.showcase-description {
  font-size: 12px;
}

footer {
  display: flex;
  flex-direction: column;
  background-color: #464646;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 32px;
  padding-top: 16px;
  padding-bottom: 64px;
}

.inner-body {
  margin-left: 150px;
  margin-right: 150px;
}

.footer-text {
  margin: 0;
  color: #c0c0c0;
  margin-top: 16px;
}

.hamburger-menu {
  display: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  background-color: white;
}

@media screen and (max-width: 600px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .showcase {
    flex-direction: column;
  }

  .showcase-title {
    text-align: center;
  }

  .hamburger-menu {
    display: block;
  }

  .header-item {
    display: none;
  }

  .inner-body {
    margin: 0;
  }

  .content-img {
    display: none;
  }
}